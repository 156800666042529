@import '../config';
@import '../core/breakpoints';

html,
body {
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-family: $font-optima;
	color: $gold;
}

h1, h2, h3, h4, h5 {
	margin-bottom: 0.5em;
}

h1 {
	margin-bottom: 60px;
	font-family: $font-optima;
	font-size: $h1-font-size;
	line-height: 1.25;
	color: $gold;
	text-align: center;

	@include max1024px {
		font-size: 60px;
	}

	@include max640px {
		font-size: 50px;
		margin-bottom: 30px;
	}
}

h2 {
	font-size: $h2-font-size;
	font-weight: 400;
	color: $gold;
	line-height: 1.125;
	text-transform: uppercase;
	@include max1024px {
		font-size: 40px;
	}
}

h3 {
	font-size: $h3-font-size;
	line-height: 1.1;
}

h4 {
	font-size: $h4-font-size;
	line-height: 1.05;
}

p {
	font-size: $base-paragraph-size;

}

strong {
	font-weight: 600;
}

a {
	color: inherit;
	@if ($underlined-links == true) {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	} @else {
		text-decoration: none;
	}
}

.perex {
	color: $black;
	font-size: 1.2em;
}

.section__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: center;
    color: $white;
    position: relative;
    width: fit-content;
    margin: 0 auto 1rem;
    &:after {
        content: "";
        position: absolute;
        top: 110%;
        width: 100%;
        left: 50%;
        transform: translate(-50%);
        background: $gold;
        height: 3px;
    }
}
@import '../config';

@font-face {
	font-display: auto;
}

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com
*/

@font-face {
	font-family: 'Optima CE'; /*Basic*/
	src: url("#{$fonts-path}/5962254/cf5e912a-25b2-4f77-a4fc-d8ff36cf891d.woff2") format('woff2'),
	url("#{$fonts-path}/5962254/6c4db7fc-5154-4b66-8fd1-0978db11f68b.woff") format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Optima CE'; /*Italic*/
	src: url("#{$fonts-path}/5962250/6ac5c559-f917-4392-a16c-93437a46210d.woff2") format('woff2'),
	url("#{$fonts-path}/5962250/82dd84eb-4a5f-4e73-8e30-745aa9e918d5.woff") format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Optima CE'; /*Medium*/
	src: url("#{$fonts-path}/5962248/db9bd2a5-7cc2-48cb-a18a-2fe7c470b436.woff2") format('woff2'),
	url("#{$fonts-path}/5962248/37464bb7-6fd2-4b40-bd6c-b2a5b82cf5ba.woff") format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Optima CE'; /*Bold italic*/
	src: url("#{$fonts-path}/5962285/e2eb8612-9b9e-4f98-bd80-b2085fe2c5ba.woff2") format('woff2'),
	url("#{$fonts-path}/5962285/d5c33c59-6da6-444e-9bd7-4b6f70921a83.woff") format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Optima CE'; /*Bold*/
	src: url("#{$fonts-path}/5962258/eeb7bc1b-a40d-4244-992e-163a4034a0ed.woff2") format('woff2'),
	url("#{$fonts-path}/5962258/2b1527e8-792e-435e-a575-782d8c118200.woff") format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Optima CE'; /*Black*/
	src: url("#{$fonts-path}/5962274/89af3881-4ea0-4cbf-9912-874122dd99e7.woff2") format('woff2'),
	url("#{$fonts-path}/5962274/e2e2eaa8-3121-449b-a034-52e1b3260293.woff") format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Optima CE'; /*Black italic*/
	src: url("#{$fonts-path}/5962270/bd848ca0-2586-4167-9582-45fe22b7f7fe.woff2") format('woff2'),
	url("#{$fonts-path}/5962270/eb08f12a-52f6-411a-b8e3-8c9583b6857b.woff") format('woff');
	font-weight: 800;
	font-style: italic;
}

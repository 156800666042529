@import '../config';

* {
    box-sizing: border-box;
}

body {
	padding: 0;
	margin: 0;
    background: $red;
    background-size: 100% auto;
}  

.wrapper {
	max-width: $grid-max-width;
	width: 100%;
	margin: 0 auto;
	padding: 0 1rem;
	box-sizing: border-box;
}

table {
	border-collapse: collapse;
}

.buttonRow {
	display: flex;
	justify-content: center;
	.button {
		margin: 0 1rem;
	}
}

.separator {
    margin: auto;
    display: block;
}